var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('h1', [_vm._v("Zoom Meeting SDK Sample Vue.js 2")]), _c('button', {
    on: {
      "click": _vm.getSignature
    }
  }, [_vm._v("Join Meeting")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }