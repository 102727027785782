<template>
  <main>
    <h1>Zoom Meeting SDK Sample Vue.js 2</h1>
    <button @click="getSignature">Join Meeting</button>
  </main>
</template>

<script>
import axios from 'axios'
import { ZoomMtg } from '@zoomus/websdk'

export default {
  name: 'HelloWorld',
  created() {
    ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.5/lib', '/av')
    ZoomMtg.preLoadWasm()
    ZoomMtg.prepareWebSDK()
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load('en-US')
    ZoomMtg.i18n.reload('en-US')
  },
  mounted() {
    ZoomMtg.inMeetingServiceListener('onUserJoin', (data) => {
      // console.log('inMeetingServiceListener onUserJoin', data)
    })
  },
  data() {
    return {
      sdkKey: '58UM8_chRie5JGQeUcaI_g',
      leaveUrl: 'http://localhost:8080',
      meetingNumber: '84555095195',
      passWord: '',
      role: 0,
      signatureEndpoint: '',
      userEmail: '',
      userName: 'Vue.js',
      registrantToken: ''
    }
  },
  methods: {
    getSignature() {
      axios
        .post(this.signatureEndpoint, {
          meetingNumber: this.meetingNumber,
          role: this.role
        })
        .then((res) => {
          // console.log(res.data.signature)
          this.startMeeting(res.data.signature)
        })
        .catch((error) => {
          error
        })
    },
    startMeeting(signature) {
      document.getElementById('zmmtg-root').style.display = 'block'

      ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        success: (success) => {
          // console.log(success)
          ZoomMtg.join({
            meetingNumber: this.meetingNumber,
            userName: this.userName,
            signature: signature,
            sdkKey: this.sdkKey,
            userEmail: this.userEmail,
            passWord: this.passWord,
            tk: this.registrantToken,
            success: (success) => {
                success
              // console.log(success)
            },
            error: (error) => {
              error
            }
          })
        },
        error: (error) => {
          error
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
  width: 70%;
  margin: auto;
  text-align: center;
}

main button {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681f2;
}
</style>
